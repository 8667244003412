<template>
    <div class="wrap">
      <TablePage v-model="options" @handleEvent="handleEvent" />
    </div>
  </template>
  <script>
  import TablePage from "@/components/tablePage";
  import { O2O_PAY_TYPE_DATA, O2O_ORDER_STATUS_DATA } from "@/utils/constant.js";
  import { fcount } from "@/utils";
  import { orderList } from "@/api/O2OMall/orderManage/order";
  export default {
    name: "orderList",
    components: { TablePage },
    dicts: ["order_status", "fetch_type", "order_type"],
    data() {
      return {
        options: {
          loading: true,
          pagination: {
            total: 0,
            page: 1,
            size: 15,
          },
          getListApi: orderList,
          body: {},
          listNo: true, // 序号
          check: [], // 选中数据
          exportOption: {
            exportApi: "exportOrder",
            exportName: "商城订单列表",
          },
          search: [
            {
              label: "下单时间",
              type: "datetimerange",
              model: "",
              filter: "orderDate",
              // filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
            },
            {
              keyIndex: 0,
              format: "datetimerange",
              type: "timeRange",
              defaultValue: "month",
              data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
            },
            {
              type: "filters",
              filters: [
                { filter: "query", label: "全部" },
                { filter: "billNo", label: "订单编号" },
                { filter: "receiverName", label: "提(收)货人姓名" },
                { filter: "receiverPhone", label: "电话" },
                { filter: "vipNo", label: "会员卡号" },
              ],
            },
            {
              label: "配送时间",
              type: "datetimerange",
              model: "",
              filter: "fetchDate",
              seniorSearch: true,
            },
            this.$select({
              key: "fetch_type",
              option: {
                seniorSearch: true,
                option: {
                  multiple: true,
                },
              },
            }),
            {
              type: "local",
              model: "",
              filter: "payType",
              label: "支付方式",
              seniorSearch: true,
              option: {
                multiple: true,
                data: O2O_PAY_TYPE_DATA,
                label: "label",
                value: "value",
              },
            },
            // this.$select({
            //   key: "order_type",
            //   option: {
            //     seniorSearch: true,
            //     option: {
            //       multiple: true,
            //     },
            //   },
            // }),
            this.$select({
              key: "order_status",
              option: {
                seniorSearch: true,
                option: {
                  multiple: true,
                },
              },
            }),
            { type: "button", tip: "查询", btnType: "primary", click: "search" },
            {
              type: "button",
              tip: "高级搜索",
              btnType: "primary",
              click: "seniorSearch",
            },
            { type: "button", tip: "重置", click: "reset" },
          ],
          buttons: [
            // {
            //   click: "out",
            //   label: "导出",
            //   icon: "el-icon-upload2",
            //   right: true,
            //   type: "",
            // },
            {
              click: "refresh",
              label: "刷新",
              icon: "el-icon-refresh",
              right: true,
              type: "",
            },
          ],
          summary: [
            "orderQty",
            "orderOldMoney",
            "orderDiscountMoney",
            "deliveryFee",
            "orderMoney",
          ],
          columns: [
            {
              prop: "billNo",
              label: "订单编号",
              type: "link",
              click: "toDeail",
              fixed: true,
              minWidth: 180,
            },
            {
              prop: "billDate",
              label: "订单时间",
              minWidth: 160,
            },
            {
              type: "dict",
              dict: "order_type",
              prop: "orderType",
              label: "订单类型",
              minWidth: 100,
            },
            {
              prop: "orderSubTypeName",
              label: "订单子类型",
              minWidth: 100,
            },
            {
              type: "dict",
              dict: "order_status",
              prop: "orderStatus",
              label: "订单状态",
              minWidth: 100,
            },
            {
              prop: "orderInnerStatusName",
              label: "完成进度",
              minWidth: 100,
            },
            {
              prop: "produceDeptName",
              label: "受理门店",
              minWidth: 150,
            },
            {
              prop: "orderQty",
              label: "商品数量",
              minWidth: 80,
            },
            {
              prop: "orderOldMoney",
              label: "订单金额",
              minWidth: 110,
              formatter: (v, row) =>
                fcount([row.orderMoney, row.deliveryFee || row.expressFee || 0], "-"),
            },
            {
              prop: "orderDiscountMoney",
              label: "优惠金额",
              minWidth: 110,
            },
            {
              prop: "deliveryFee",
              label: "配送费用", // expressFee
              minWidth: 110,
              formatter: (v, row) => v || row.expressFee || "",
            },
            {
              prop: "orderMoney",
              label: "本单合计金额",
              minWidth: 110,
            },
            {
              prop: "fetchDate",
              label: "配送时间",
              minWidth: 190,
              formatter: (v, row) => `${row.fetchDate || ""} ${row.fetchTime || ""}`,
            },
            {
              type: "dict",
              dict: "fetch_type",
              prop: "fetchType",
              label: "配送方式",
              minWidth: 100,
            },
            {
              prop: "payModeName",
              label: "支付方式",
              minWidth: 100,
            },
            {
              prop: "vipNo",
              label: "会员卡号",
              align: "center",
              minWidth: 175,
            },
            {
              prop: "receiverName",
              label: "提(收)货人姓名",
              minWidth: 120,
            },
            {
              prop: "receiverPhone",
              label: "电话",
              minWidth: 110,
            },
            {
              prop: "receiverAddress",
              label: "地址",
              minWidth: 180,
              formatter: (v, row) =>
                `${row.receiverAddress || ""} ${row.receiverRegionAddress || ""}`,
            },
            {
              prop: "remark",
              label: "备注",
              minWidth: 100,
            },
          ],
          list: [],
        },
      };
    },
    methods: {
      async handleEvent(type, row) {
        switch (type) {
          case "dialogChange":
            break;
          case "toDeail":
            this.$router.push({
              path: "/O2OMall/order/list/orderManage/index",
              query: { billId: row.billId, orderShopId: row.orderShopId },
            });
            break;
          default:
            break;
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .wrap {
    .dialog-footer {
      height: 80px;
    }
  }
  </style>
  