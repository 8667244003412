<template>
    <div class="wrap">
      <TablePage v-model="options" @handleEvent="handleEvent" />
    </div>
  </template>
  <script>
  import TablePage from "@/components/tablePage";
  import { O2O_PAY_TYPE_DATA, O2O_ORDER_STATUS_DATA } from "@/utils/constant.js";
  
  import { orderReserveDetailList } from "@/api/O2OMall/orderManage/goodsQuery";
  export default {
    name: "orderList",
    components: { TablePage },
    data() {
      return {
        options: {
          loading: true,
          pagination: {
            total: 0,
            page: 1,
            size: 15,
          },
          getListApi: orderReserveDetailList,
          body: {},
          listNo: true, // 序号
          check: [], // 选中数据
          search: [
            {
              label: "下单时间",
              type: "datetimerange",
              model: "",
              filter: "orderDate",
            },
            {
              keyIndex: 0,
              format: "datetimerange",
              type: "timeRange",
              defaultValue: "month",
              data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
            },
            {
              type: "filters",
              filters: [
                { filter: "query", label: "全部" },
                { filter: "categoryName", label: "商品分类" },
                { filter: "goodsNo", label: "商品编码" },
                { filter: "goodsName", label: "商品名称" },
                { filter: "barcode", label: "条码" },
              ],
            },
            {
              label: "配送时间",
              type: "datetimerange",
              model: "",
              seniorSearch: true,
              filter: "fetchDate",
            },
            { type: "button", tip: "查询", btnType: "primary", click: "search" },
            {
              type: "button",
              tip: "高级搜索",
              btnType: "primary",
              click: "seniorSearch",
            },
            { type: "button", tip: "重置", click: "reset" },
          ],
          buttons: [
            {
              click: "out",
              label: "导出",
              icon: "el-icon-upload2",
              right: true,
              type: "",
            },
            {
              click: "refresh",
              label: "刷新",
              icon: "el-icon-refresh",
              right: true,
              type: "",
            },
          ],
          columns: [
            {
              prop: "categoryNo",
              label: "类别编号",
              minWidth: 100,
            },
            {
              prop: "categoryName",
              label: "类别名称",
              minWidth: 160,
            },
            {
              prop: "goodsNo",
              label: "商品编码",
              minWidth: 100,
            },
            {
              prop: "goodsName",
              label: "商品名称",
              minWidth: 160,
            },
            {
              prop: "barcode",
              label: "条码",
              minWidth: 120,
            },
            {
              prop: "goodsSpec",
              label: "规格",
              minWidth: 120,
            },
            {
              prop: "unitName",
              label: "单位",
              minWidth: 120,
            },
            {
              prop: "unitQty",
              label: "销售数量",
              align: "center",
              minWidth: 120,
            },
            {
              prop: "unitMoney",
              label: "销售金额",
              minWidth: 140,
            },
          ],
          list: [],
        },
      };
    },
    methods: {
      async handleEvent(type, row) {
        switch (type) {
          case "dialogChange":
            break;
          default:
            break;
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .wrap {
    .dialog-footer {
      height: 80px;
    }
  }
  </style>
  