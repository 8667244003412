var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mainOrderBody.billNo
    ? _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "bg-Color tableBox padd15 marT10" },
          [
            _c("el-descriptions", { attrs: { title: "订单信息" } }),
            _c(
              "div",
              { staticClass: "detail-view" },
              [
                _c(
                  "el-descriptions",
                  { attrs: { column: 1 } },
                  [
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "订单编号" } },
                      [_vm._v(_vm._s(_vm.mainOrderBody.billNo))]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "订单类型" } },
                      _vm._l(_vm.dict.type.order_type, function (item) {
                        return _c("div", { key: item.value }, [
                          item.value == _vm.mainOrderBody.orderType
                            ? _c("span", [_vm._v(_vm._s(item.label))])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "订单金额" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.mainOrderBody.orderMoney -
                              (_vm.mainOrderBody.deliveryFee ||
                                _vm.mainOrderBody.expressFee ||
                                0)
                          )
                        ),
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "优惠金额" } },
                      [
                        _vm._v(
                          _vm._s(_vm.mainOrderBody.orderDiscountMoney || 0)
                        ),
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "配送费用" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.mainOrderBody.deliveryFee ||
                              _vm.mainOrderBody.expressFee ||
                              0
                          )
                        ),
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "本单合计金额" } },
                      [_vm._v(_vm._s(_vm.mainOrderBody.orderMoney || 0))]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "支付方式" } },
                      [_vm._v(_vm._s(_vm.mainOrderBody.orderPayMode))]
                    ),
                    _c("el-descriptions-item", { attrs: { label: "优惠券" } }, [
                      _vm._v(" " + _vm._s(_vm.mainOrderBody.couponsName) + " "),
                    ]),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "支付状态" } },
                      [
                        _vm.mainOrderBody.orderStatus == 1
                          ? _c("span", [_vm._v("待付款")])
                          : _vm._e(),
                        _vm.mainOrderBody.orderStatus > 1 &&
                        _vm.mainOrderBody.orderStatus < 100
                          ? _c("span", [_vm._v("已付款")])
                          : _vm._e(),
                        _vm.mainOrderBody.orderStatus >= 100
                          ? _c("span", [_vm._v("已退款")])
                          : _vm._e(),
                      ]
                    ),
                    _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                      _vm._v(_vm._s(_vm.mainOrderBody.remark)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-descriptions",
                  { attrs: { column: 1 } },
                  [
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "配送方式" } },
                      _vm._l(_vm.dict.type.fetch_type, function (item) {
                        return _c("div", { key: item.value }, [
                          item.value == _vm.mainOrderBody.fetchType
                            ? _c("span", [_vm._v(_vm._s(item.label))])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                    _c(
                      "el-descriptions-item",
                      {
                        attrs: {
                          label:
                            (_vm.mainOrderBody.fetchType == 1
                              ? "自提"
                              : "配送") + "门店",
                        },
                      },
                      [_vm._v(_vm._s(_vm.mainOrderBody.shopName))]
                    ),
                    _c(
                      "el-descriptions-item",
                      {
                        attrs: {
                          label:
                            (_vm.mainOrderBody.fetchType == 1
                              ? "自提"
                              : "配送") + "时间",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.mainOrderBody.fetchType == 1
                                ? (_vm.fetcherBody.fetchDate
                                    ? _vm.fetcherBody.fetchDate.slice(0, 10)
                                    : "") +
                                    " " +
                                    (_vm.fetcherBody.fetchTime || "")
                                : (_vm.receiverBody.receiveDate
                                    ? _vm.receiverBody.receiveDate.slice(0, 10)
                                    : "") +
                                    " " +
                                    (_vm.receiverBody.receiveTime || "")
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      {
                        attrs: {
                          label:
                            (_vm.mainOrderBody.fetchType == 1
                              ? "门店"
                              : "配送") + "地址",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.mainOrderBody.fetchType == 1
                                ? _vm.mainOrderBody.shopAddress || ""
                                : (_vm.receiverBody.receiverAddress || "") +
                                    " " +
                                    (_vm.receiverBody.receiverRegionAddress ||
                                      "")
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "联 系 人" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.mainOrderBody.fetchType == 1
                              ? _vm.fetcherBody.fetchName
                              : _vm.receiverBody.receiverName
                          )
                        ),
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "手 机 号" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.mainOrderBody.fetchType == 1
                              ? _vm.fetcherBody.fetchPhone
                              : _vm.receiverBody.receiverPhone
                          )
                        ),
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "会员卡号" } },
                      [_vm._v(_vm._s(_vm.mainOrderBody.vipNo))]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "会员级别" } },
                      [_vm._v(_vm._s(_vm.mainOrderBody.vipLevelName))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bg-Color tableBox padd15 marT10" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.orderItems, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "goodsName",
                    label: "商品名称(总计：" + _vm.orderItems.length + "件)",
                    align: "center",
                    "min-width": "180",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "goodsNo",
                    label: "商品编码",
                    align: "center",
                    "min-width": "160",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "barcode",
                    label: "条码",
                    align: "center",
                    "min-width": "160",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "goodsSpec",
                    label: "规格",
                    align: "center",
                    "min-width": "100",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "unitName",
                    label: "单位",
                    align: "center",
                    "min-width": "100",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "machining",
                    label: "加值属性",
                    align: "center",
                    "min-width": "200",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "unitPrice",
                    label: "售价",
                    align: "center",
                    "min-width": "100",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "unitQty",
                    label: "数量",
                    align: "center",
                    "min-width": "100",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "unitMoney",
                    label: "金额",
                    align: "center",
                    "min-width": "100",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }