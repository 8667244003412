<template>
    <div class="wrap">
      <TablePage v-model="options" @handleEvent="handleEvent" />
    </div>
  </template>
  <script>
  import TablePage from "@/components/tablePage";
  import { O2O_PAY_TYPE_DATA, O2O_ORDER_STATUS_DATA } from "@/utils/constant.js";
  
  import { reservePayDetailList } from "@/api/O2OMall/orderManage/order";
  export default {
    name: "orderList",
    components: { TablePage },
    dicts: ["order_status"],
    data() {
      return {
        options: {
          loading: true,
          pagination: {
            total: 0,
            page: 1,
            size: 15,
          },
          getListApi: reservePayDetailList,
          body: {},
          listNo: true, // 序号
          check: [], // 选中数据
          search: [
            {
              label: "下单时间",
              type: "datetimerange",
              model: "",
              filter: "orderDate",
            },
            {
              keyIndex: 0,
              format: "datetimerange",
              type: "timeRange",
              defaultValue: "month",
              data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
            },
            {
              type: "filters",
              filters: [
                { filter: "query", label: "全部" },
                { filter: "billNo", label: "订单编号" },
                { filter: "vipNo", label: "会员卡号" },
                { filter: "vipName", label: "会员名称" },
                { filter: "tel", label: "手机" },
              ],
            },
            {
              label: "支付时间",
              type: "datetimerange",
              model: "",
              seniorSearch: true,
              filter: "payDate",
            },
            {
              type: "local",
              model: "",
              filter: "payType",
              label: "支付方式",
              seniorSearch: true,
              option: {
                data: O2O_PAY_TYPE_DATA,
                label: "label",
                value: "value",
                multiple: true,
              },
            },
            { type: "button", tip: "查询", btnType: "primary", click: "search" },
            {
              type: "button",
              tip: "高级搜索",
              btnType: "primary",
              click: "seniorSearch",
            },
            { type: "button", tip: "重置", click: "reset" },
          ],
          buttons: [
            {
              click: "out",
              label: "导出",
              icon: "el-icon-upload2",
              right: true,
              type: "",
            },
            {
              click: "refresh",
              label: "刷新",
              icon: "el-icon-refresh",
              right: true,
              type: "",
            },
          ],
          summary: ["orderPayMoney"],
          columns: [
            {
              prop: "billNo",
              label: "订单编号",
              minWidth: 120,
            },
            {
              prop: "billDate",
              label: "下单时间",
              minWidth: 160,
            },
            {
              type: "dict",
              dict: "order_status",
              prop: "orderStatus",
              label: "订单状态",
              minWidth: 100,
            },
            {
              prop: "orderMoney",
              label: "实付金额",
              minWidth: 120,
            },
            {
              prop: "payModeName",
              label: "支付方式",
              align: "center",
              minWidth: 150,
              children: () =>
                this.options.list?.[0]?.payTypes?.map?.((x) => ({
                  ...x,
                  label: x.value,
                  prop: `orderPayResps.${x.payMode}.payModeId.payMoney`,
                  minWidth: 110,
                  summary: true,
                  getValue: (row, prop) =>
                    row.orderPayResps?.find((y) => y.payModeId === x.payMode)?.payMoney ||
                    0,
                })) || [],
            },
            {
              prop: "orderPayTime",
              label: "支付时间",
              minWidth: 160,
            },
            {
              prop: "vipNo",
              label: "会员卡号",
              align: "center",
              minWidth: 175,
            },
            {
              prop: "vipName",
              label: "会员名称",
              align: "center",
              minWidth: 175,
            },
            {
              prop: "tel",
              label: "手机",
              align: "center",
              minWidth: 175,
            },
          ],
          list: [],
        },
      };
    },
    methods: {
      async handleEvent(type, row) {
        switch (type) {
          case "dialogChange":
            break;
          default:
            break;
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .wrap {
    .dialog-footer {
      height: 80px;
    }
  }
  </style>
  