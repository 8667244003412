<template>
  <div class="container" v-if="mainOrderBody.billNo">
    <div class="bg-Color tableBox padd15 marT10">
      <el-descriptions title="订单信息" />
      <div class="detail-view">
        <el-descriptions :column="1">
          <el-descriptions-item label="订单编号">{{
            mainOrderBody.billNo
          }}</el-descriptions-item>
          <el-descriptions-item label="订单类型">
            <div v-for="item in dict.type.order_type" :key="item.value">
              <span v-if="item.value == mainOrderBody.orderType">{{ item.label }}</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="订单金额">{{
            mainOrderBody.orderMoney -
            (mainOrderBody.deliveryFee || mainOrderBody.expressFee || 0)
          }}</el-descriptions-item>
          <el-descriptions-item label="优惠金额">{{
            mainOrderBody.orderDiscountMoney || 0
          }}</el-descriptions-item>
          <el-descriptions-item label="配送费用">{{
            mainOrderBody.deliveryFee || mainOrderBody.expressFee || 0
          }}</el-descriptions-item>
          <el-descriptions-item label="本单合计金额">{{
            mainOrderBody.orderMoney || 0
          }}</el-descriptions-item>

          <el-descriptions-item label="支付方式">{{
            mainOrderBody.orderPayMode
          }}</el-descriptions-item>
          <el-descriptions-item label="优惠券">
            {{ mainOrderBody.couponsName }}
          </el-descriptions-item>
          <el-descriptions-item label="支付状态">
            <span v-if="mainOrderBody.orderStatus == 1">待付款</span>
            <span v-if="mainOrderBody.orderStatus > 1 && mainOrderBody.orderStatus < 100"
              >已付款</span
            >
            <span v-if="mainOrderBody.orderStatus >= 100">已退款</span>
          </el-descriptions-item>
          <el-descriptions-item label="备注">{{
            mainOrderBody.remark
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="1">
          <el-descriptions-item label="配送方式">
            <div v-for="item in dict.type.fetch_type" :key="item.value">
              <span v-if="item.value == mainOrderBody.fetchType">{{ item.label }}</span>
            </div>
          </el-descriptions-item>

          <el-descriptions-item
            :label="`${mainOrderBody.fetchType == 1 ? '自提' : '配送'}门店`"
            >{{ mainOrderBody.shopName }}</el-descriptions-item
          >
          <el-descriptions-item
            :label="`${mainOrderBody.fetchType == 1 ? '自提' : '配送'}时间`"
          >
            {{
              mainOrderBody.fetchType == 1
                ? `${fetcherBody.fetchDate ? fetcherBody.fetchDate.slice(0, 10) : ""} ${
                    fetcherBody.fetchTime || ""
                  }`
                : `${
                    receiverBody.receiveDate ? receiverBody.receiveDate.slice(0, 10) : ""
                  } ${receiverBody.receiveTime || ""}`
            }}
          </el-descriptions-item>
          <el-descriptions-item
            :label="`${mainOrderBody.fetchType == 1 ? '门店' : '配送'}地址`"
          >
            {{
              mainOrderBody.fetchType == 1
                ? mainOrderBody.shopAddress || ""
                : `${receiverBody.receiverAddress || ""} ${
                    receiverBody.receiverRegionAddress || ""
                  }`
            }}
          </el-descriptions-item>
          <el-descriptions-item label="联 系 人">{{
            mainOrderBody.fetchType == 1
              ? fetcherBody.fetchName
              : receiverBody.receiverName
          }}</el-descriptions-item>
          <el-descriptions-item label="手 机 号">{{
            mainOrderBody.fetchType == 1
              ? fetcherBody.fetchPhone
              : receiverBody.receiverPhone
          }}</el-descriptions-item>
          <el-descriptions-item label="会员卡号">{{
            mainOrderBody.vipNo
          }}</el-descriptions-item>
          <el-descriptions-item label="会员级别">{{
            mainOrderBody.vipLevelName
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="bg-Color tableBox padd15 marT10">
      <el-table :data="orderItems" border style="width: 100%">
        <el-table-column
          prop="goodsName"
          :label="'商品名称(总计：' + orderItems.length + '件)'"
          align="center"
          min-width="180"
        ></el-table-column>
        <el-table-column
          prop="goodsNo"
          label="商品编码"
          align="center"
          min-width="160"
        ></el-table-column>
        <el-table-column
          prop="barcode"
          label="条码"
          align="center"
          min-width="160"
        ></el-table-column>
        <el-table-column
          prop="goodsSpec"
          label="规格"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="unitName"
          label="单位"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="machining"
          label="加值属性"
          align="center"
          min-width="200"
        ></el-table-column>
        <el-table-column
          prop="unitPrice"
          label="售价"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="unitQty"
          label="数量"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="unitMoney"
          label="金额"
          align="center"
          min-width="100"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import CButton from "@/views/components/c-button/index.vue";
import cardTitleCom from "@/views/components/cardTitleCom";
import { getOrderDetail, shopBasPayList } from "@/api/O2OMall/orderManage/order"; //卡片标题
export default {
  name: "index",
  components: {
    cardTitleCom,
    CButton,
  },
  dicts: ["order_status", "fetch_type", "pay_method", "fetch_type", "order_type"],
  data() {
    return {
      tableData: [],
      billId: null,
      orderShopId: null,
      queryParams: {
        total: 0,
        pageSize: 15,
        pageNum: 1,
      },
      detail: {}, // 订单详情
      mainOrderBody: {},
      orderItems: [],
      receiverBody: {},
      fetcherBody: {},
    };
  },
  computed: {},
  created() {
    this.billId = this.$route.query.billId;
    this.orderShopId = this.$route.query.orderShopId;
    // console.log('======:', typeof this.billId)
    // console.log('======:', this.billId)
    this.getDetail();
    // this.getShopBasPayList()
  },
  methods: {
    getDetail() {
      getOrderDetail({
        billId: this.billId,
      }).then((res) => {
        console.log("订单详情：", res.data);
        let {
          fetcherBody,
          mainOrderBody,
          orderItems,
          receiverBody,
          expressBody,
        } = res.data;
        this.fetcherBody = fetcherBody || {};
        let coupons = [];
        if (mainOrderBody?.coupons?.length) {
          mainOrderBody?.coupons?.forEach?.((coupon) => {
            const index = coupons.findIndex(
              (x) => x.couponCaseId === coupon.couponCaseId
            );
            if (index >= 0) {
              coupons[index].useCouponNumber = coupons[index].useCouponNumber + 1;
            } else {
              coupons.push({ ...coupon, useCouponNumber: 1 });
            }
          });
        }
        const couponsName = coupons
          .map((x) => `${x.couponName} ${x.useCouponNumber}张`)
          .join("、");
        this.mainOrderBody = { ...mainOrderBody, couponsName };
        this.orderItems = orderItems;
        this.receiverBody = receiverBody || expressBody || {};
      });
    },
    getShopBasPayList() {
      shopBasPayList({
        orderShopId: this.orderShopId,
      }).then((res) => {});
    },
    editBtn() {},
    getList() {},
    // 订单状态
    getOrderType(type) {
      let typeStr;
      switch (type) {
        case 1:
          typeStr = "自营商城";
          break;
        case 2:
          typeStr = "美团订单";
          break;
        case 3:
          typeStr = "饿了么订单";
          break;
        case 4:
          typeStr = "有赞订单";
          break;
      }
      return typeStr;
    },
    //  组装收货人信息
    setReceiverMessage() {
      return (
        this.detail.receiverName +
        " " +
        this.detail.receiverPhone +
        " " +
        this.detail.receiverRegionAddress
      );
    },
    getOrderPayMode(id) {},
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  background: #e9e9e9;

  .tableBox {
    padding: 10px;
  }
  .detail-view {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
</style>
